import { SortDirection } from "@meplato/ui-kit";

import { Config } from "lib/tenant";
import { View } from "lib/view";

import {
  FilterParam,
  Filters,
  Sort,
  SortColumn,
  SortParam,
  VendorCatalogValue,
} from "../types";
import { PAGE_SIZE } from "./constants";

export * from "./validations";

const filterParsers = {
  searchQuery: (params: URLSearchParams) => {
    const value = params.get(FilterParam.FreeTextSearch);
    return value?.trim() ?? "";
  },
};

const parseSortParam = (
  param: string,
): { column: string; direction: SortDirection } => {
  const direction: SortDirection =
    param[0] === "-" ? "descending" : "ascending";
  const column = direction === "descending" ? param.slice(1) : param;

  return {
    column,
    direction,
  };
};

export const urlParamsToFilters = (params: URLSearchParams): Filters => {
  return {
    searchQuery: filterParsers.searchQuery(params),
  };
};

export const urlParamsToSort = (params: URLSearchParams): Sort => {
  const sortParam = params.get(SortParam.Sort);
  if (sortParam) {
    const { column, direction } = parseSortParam(sortParam);
    const isSortable = getSortableColumnKeys().includes(column as SortColumn);
    if (isSortable) {
      return {
        column: column as SortColumn,
        direction,
      };
    }
  }

  // Default sort
  return {
    column: SortColumn.Name,
    direction: "ascending",
  };
};

export const filtersToUrlSearchParams = (filters?: Partial<Filters>) => {
  const searchParams = new URLSearchParams();

  const searchParamParsers: Record<keyof Filters, () => void> = {
    searchQuery: () => {
      const value = filters?.searchQuery?.trim?.() ?? "";

      searchParams.set(FilterParam.FreeTextSearch, value);
    },
  };

  if (filters) {
    (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
      const parser = searchParamParsers[key];
      parser?.();
    });
  }

  return searchParams;
};

export const sortToUrlSearchParams = (sort?: Partial<Sort>) => {
  const searchParams = new URLSearchParams();

  if (sort?.column) {
    const sortParam = `${sort.direction === "descending" ? "-" : ""}${sort.column}`;
    searchParams.set(SortParam.Sort, sortParam);
  }

  return searchParams;
};

export const cleanUrlSearchParams = (params: URLSearchParams) => {
  const newSearchParams = new URLSearchParams();

  for (const [key, value] of params) {
    if (value) {
      newSearchParams.set(key, value);
    }
  }

  return newSearchParams;
};

export const serializeParams = ({
  filters,
  page,
  sort,
  viewId,
  config,
}: {
  filters?: Partial<Filters>;
  page?: number;
  sort?: Partial<Sort>;
  viewId?: string;
  config?: Config;
}) => {
  const urlParams = new URLSearchParams();
  if (!viewId && config && config.customer_views) {
    urlParams.set("showOnlyParents", "true");
  }

  urlParams.set("pageSize", PAGE_SIZE.toString());
  urlParams.set("page", page?.toString() || "1");
  if (viewId) {
    urlParams.set("parentId", viewId);
  }

  const filterMapping: Record<keyof Filters, () => void> = {
    searchQuery: () => {
      const value = filters?.searchQuery?.trim?.();

      if (value) {
        urlParams.set(FilterParam.FreeTextSearch, value);
      }
    },
  };

  if (filters) {
    (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
      filterMapping[key]?.();
    });
  }

  if (sort?.column) {
    urlParams.set(
      SortParam.Sort,
      sort.direction === "ascending" ? sort.column : `-${sort.column}`,
    );
  }

  return urlParams;
};

export const getSortableColumnKeys = () => Object.values(SortColumn);

export const isEmptyFilters = (filters: Filters) => {
  if (filters.searchQuery) return false;

  return true;
};

/**
 * Get the ids of the vendors that should be excluded in the view.
 * @param currentView The current view
 * @param selectedVendors The selected vendors
 * @returns Array of vendor IDs to exclude
 */
export const getExcludeVendorIds = (
  currentView: View,
  selectedVendors: VendorCatalogValue[],
) => {
  if (!currentView.vendors) {
    return [];
  }

  const selectedIds = selectedVendors.map((vendor) => vendor.vendorId);

  return currentView.vendors
    .filter(({ excluded, hasActiveCatalogs: visible, vendorId }) => {
      // Check for already excluded vendors
      if (excluded && !visible) return true;

      // Check for non-selected vendors
      return visible && !selectedIds.includes(vendorId);
    })
    .map(({ vendorId }) => vendorId);
};
