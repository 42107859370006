import { createTenant, deleteTenant, patchTenant } from "api/tenant";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import { Currency } from "lib/currency";
import { Language } from "lib/language";
import { Region } from "lib/region";
import { CreateTenantRequest, PatchTenantRequest, Tenant } from "lib/tenant";
import { UsersSearchResponse } from "lib/user";
import { ViewTuple } from "lib/view";

export interface TenantState {
  tenantId: number;
  error?: any;
  tenant?: Tenant;
  isValidating: boolean;
  languages?: Language[];
  regions?: Region[];
  currencies?: Currency[];
  views?: ViewTuple[];
  adminsCount?: UsersSearchResponse;
  refresh: () => Promise<void>;
  createTenant: (request: CreateTenantRequest) => Promise<Tenant>;
  patchTenant: (request: PatchTenantRequest) => Promise<Tenant>;
  deleteTenant: () => Promise<any>;
}

const initialState: TenantState = {
  tenantId: 0,
  isValidating: false,
  refresh: () => Promise.reject(),
  createTenant: () => Promise.reject(),
  patchTenant: () => Promise.reject(),
  deleteTenant: () => Promise.reject(),
};

/**
 * TenantContext implements a React Context.
 */
export const TenantContext = createContext<TenantState>(initialState);

/**
 * Props of the TenantProvider component.
 */
interface TenantProviderProps {
  children: React.ReactNode;
}

/**
 * TenantProvider is a React Context Provider responsible for managing tenants.
 *
 * @param props Page props
 * @returns React Functional Component
 */
export const TenantProvider = (props: TenantProviderProps) => {
  let { tenantId } = useParams();

  const languages = useSWR<Language[]>(`/ui/admin/languages`);
  const regions = useSWR<Region[]>(`/ui/admin/regions`);
  const currencies = useSWR<Currency[]>(`/ui/admin/currencies`);
  const views = useSWR<ViewTuple[]>(
    `/ui/admin/views:select?tenantId=${tenantId}`,
  );
  const adminsCount = useSWR<UsersSearchResponse>(
    `/ui/admin/adminUsers?tenantId=${tenantId}&page=1`,
  );

  const fetchResult = useSWR<Tenant>(() => {
    if (
      tenantId &&
      languages.data &&
      regions.data &&
      currencies.data &&
      views.data
    ) {
      return `/ui/admin/tenants/${tenantId}`;
    }
    return null;
  });

  const refresh = useCallback(async () => {
    fetchResult.mutate();
  }, [fetchResult]);

  const doDeleteTenant = useCallback(async () => {
    const id = Number(tenantId);
    if (id) {
      await fetchResult.mutate();
      return deleteTenant(id);
    }
    return Promise.reject();
  }, [tenantId, fetchResult]);

  const doPatchTenant = useCallback(
    async (request: PatchTenantRequest) => {
      const id = Number(tenantId);
      if (id) {
        await fetchResult.mutate();
        return patchTenant(id, request);
      }
      return Promise.reject();
    },
    [tenantId, fetchResult],
  );

  const value = useMemo(() => {
    return {
      tenantId: Number(tenantId),
      error: fetchResult.error,
      tenant: fetchResult.data,
      isValidating: fetchResult.isValidating,
      languages: languages?.data,
      regions: regions?.data,
      currencies: currencies?.data,
      views: views?.data,
      adminsCount: adminsCount?.data,
      refresh,
      createTenant,
      patchTenant: doPatchTenant,
      deleteTenant: doDeleteTenant,
    };
  }, [
    tenantId,
    fetchResult,
    languages,
    regions,
    currencies,
    views,
    adminsCount,
    refresh,
    doPatchTenant,
    doDeleteTenant,
  ]);

  return <TenantContext.Provider value={value} {...props} />;
};

/**
 * Helper to get access to the methods and state provided by TenantProvider.
 * TenantContext returns.
 *
 * @returns TenantContext
 */
export const useTenant = () => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error(`useTenant must be used within TenantProvider`);
  }
  return context;
};
