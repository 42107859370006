import { updateUserLanguage } from "api/settings";
import Announcements from "pages/Announcements";
import Bookmarks from "pages/Bookmarks";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";
import { RouteObject, useRoutes } from "react-router-dom";
import { useTimeout } from "react-use";
import { useSWRConfig } from "swr";
import { Locale } from "utils/locale";

import { AdminProvider } from "contexts/AdminContext";
import { useAuth } from "contexts/AuthContext";
import { CartProvider } from "contexts/CartContext";
import { CatalogProvider } from "contexts/CatalogContext";
import { CatalogsProvider } from "contexts/CatalogsContext";
import { ClientProvider } from "contexts/ClientContext";
import { ClientsProvider } from "contexts/ClientsContext";
import { CommonDataProvider } from "contexts/CommonDataContext";
import { EventsProvider } from "contexts/EventsContext";
import { GuidedTourProvider } from "contexts/GuidedTourContext";
import { OrderProvider } from "contexts/OrderContext";
import { ShoppingProvider } from "contexts/ShoppingContext";
import { StatusProvider } from "contexts/StatusContext";
import { TasksProvider } from "contexts/TasksContext";
import { TenantAdminsProvider } from "contexts/TenantAdminsContext";
import { TenantProvider } from "contexts/TenantContext";
import { TenantsProvider } from "contexts/TenantsContext";
import { UserProvider } from "contexts/UserContext";
import { UserSettingsProvider } from "contexts/UserSettingsContext";
import { UsersProvider } from "contexts/UsersContext";
import { VendorProvider } from "contexts/VendorContext";
import { VendorsProvider } from "contexts/VendorsContext";
import { ViewProvider } from "contexts/ViewContext";
import { ViewsProvider } from "contexts/ViewsContext";

const ForgotPassword = React.lazy(() => import("pages/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("pages/auth/ResetPassword"));
const SignIn = React.lazy(() => import("pages/auth/SignIn"));
const SignOut = React.lazy(() => import("pages/SignOut"));
const Landing = React.lazy(() => import("pages/Landing/Landing"));
const Search = React.lazy(() => import("pages/Search/Search"));
const ProductDetailsPage = React.lazy(
  () => import("pages/ProductDetails/ProductDetailsPage"),
);
const Cart = React.lazy(() => import("pages/shoppingcart/Cart"));
const Carts = React.lazy(() => import("pages/carts/Carts"));
const Orders = React.lazy(() => import("pages/Orders"));
const Settings = React.lazy(() => import("pages/Settings"));
const AmazonAuthorizeCatalog = React.lazy(
  () => import("pages/amazon/Authorize"),
);
const AmazonAuthorizeCatalogSuccess = React.lazy(
  () => import("pages/amazon/AuthorizeSuccess"),
);
const AmazonAuthorizeCatalogFailure = React.lazy(
  () => import("pages/amazon/AuthorizeFailure"),
);
const AdminDashboard = React.lazy(
  () => import("pages/admin/dashboard/Dashboard"),
);
const AdminCatalogs = React.lazy(() => import("pages/admin/catalogs/Catalogs"));
const AdminCatalogNew = React.lazy(
  () => import("pages/admin/catalogs/CatalogNew"),
);
const AdminCatalogDetail = React.lazy(
  () => import("pages/admin/catalogs/CatalogDetail"),
);
const AdminClients = React.lazy(() => import("pages/admin/clients/Clients"));
const AdminClientNew = React.lazy(
  () => import("pages/admin/clients/ClientNew"),
);
const AdminClientDetail = React.lazy(
  () => import("pages/admin/clients/ClientDetail"),
);
const AdminTenants = React.lazy(() => import("pages/admin/tenants/Tenants"));
const AdminTenantNew = React.lazy(
  () => import("pages/admin/tenants/TenantNew"),
);
const AdminTenantDetail = React.lazy(
  () => import("pages/admin/tenants/TenantDetail"),
);
const AdminTenantsAssignedAdmins = React.lazy(
  () => import("pages/admin/tenants/TenantAdmins"),
);

const AdminUsers = React.lazy(() => import("pages/admin/users/Users"));
const AdminUserNew = React.lazy(() => import("pages/admin/users/UserNew"));
const AdminUserDetail = React.lazy(
  () => import("pages/admin/users/UserDetail"),
);

const AdminBuyersDataUpload = React.lazy(
  () => import("pages/admin/buyers/BuyersDataUpload"),
);
const AdminUserManagement = React.lazy(
  () => import("pages/admin/users/UserManagement"),
);

const AdminVendors = React.lazy(() => import("pages/admin/vendors/Vendors"));
const AdminVendorNew = React.lazy(
  () => import("pages/admin/vendors/VendorNew"),
);
const AdminVendorDetail = React.lazy(
  () => import("pages/admin/vendors/VendorDetail"),
);
const AdminViews = React.lazy(() => import("pages/admin/views/Views"));
const AdminViewsNew = React.lazy(() => import("pages/admin/views/ViewNew"));
const AdminViewDetail = React.lazy(
  () => import("pages/admin/views/ViewDetail"),
);

const CombinedShoppingProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { mutate } = useSWRConfig();

  /** Relivadate the profile of the user.
   * This hook is used to refresh the user profile when
   * - the user is authenticated and
   * - the pathname changes.
   *
   * We included this in the CombinedShoppingProvider to ensure that the user profile is refreshed,
   * to get the latest announcement data.
   *
   * NOTE: On initial load of Storefront, we have the call of the profile endpoint twice.
   * Once in the AuthProvider and the other in here.
   * With a different tool like TanStack Query, we can avoid this double call.
   */
  React.useEffect(() => {
    if (!isAuthenticated && !pathname) return;

    mutate("/ui/profile");
  }, [isAuthenticated, pathname, mutate]);

  return (
    <ShoppingProvider>
      <StatusProvider>
        <CartProvider>
          <GuidedTourProvider>
            <OrderProvider>{children}</OrderProvider>
          </GuidedTourProvider>
        </CartProvider>
      </StatusProvider>
    </ShoppingProvider>
  );
};

const CombinedAdminProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => (
  <CommonDataProvider>
    <StatusProvider>
      <AdminProvider>{children}</AdminProvider>
    </StatusProvider>
  </CommonDataProvider>
);

const routes = (
  isAuthenticated: boolean,
  isRoot?: boolean,
  isAdmin?: boolean,
): RouteObject[] => [
  {
    path: "/",
    element: isAuthenticated ? <Navigate to="/hello" /> : <SignIn />,
  },
  {
    path: "/signin",
    element: isAuthenticated ? <Navigate to="/" /> : <SignIn />,
  },
  {
    path: "/signout",
    element: isAuthenticated ? <SignOut /> : <Navigate to="/" />,
  },
  {
    path: "/forgot-password",
    element: isAuthenticated ? <Navigate to="/" /> : <ForgotPassword />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
  },
  {
    path: "/settings",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <CommonDataProvider>
          <UserSettingsProvider>
            <Settings />
          </UserSettingsProvider>
        </CommonDataProvider>
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/amazon/authorize",
    element: <AmazonAuthorizeCatalog />,
  },
  {
    path: "/amazon/authorize/success",
    element: <AmazonAuthorizeCatalogSuccess />,
  },
  {
    path: "/amazon/authorize/failure",
    element: <AmazonAuthorizeCatalogFailure />,
  },
  {
    path: "/hello",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Landing />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/search",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Search />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/products/:productId",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <ProductDetailsPage />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/cart",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Cart />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/carts",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Carts />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/orders",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Orders />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/announcements/*",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Announcements />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/bookmarks/*",
    element: isAuthenticated ? (
      <CombinedShoppingProvider>
        <Bookmarks />
      </CombinedShoppingProvider>
    ) : (
      <Navigate to="/" />
    ),
  },
  {
    path: "/admin",
    element: <Navigate to="/admin/dashboard" />,
  },
  {
    path: "/admin/dashboard",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <EventsProvider>
            <TasksProvider>
              <AdminDashboard />
            </TasksProvider>
          </EventsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/catalogs",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <CatalogsProvider>
            <AdminCatalogs />
          </CatalogsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/catalogs/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <CatalogProvider>
            <AdminCatalogNew />
          </CatalogProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/catalogs/:catalogId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <CatalogProvider>
            <AdminCatalogDetail />
          </CatalogProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/clients",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ClientsProvider>
            <AdminClients />
          </ClientsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/clients/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ClientProvider>
            <AdminClientNew />
          </ClientProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/clients/:clientId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ClientProvider>
            <AdminClientDetail />
          </ClientProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/tenants",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <TenantsProvider>
            <AdminTenants />
          </TenantsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/tenants/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <TenantProvider>
            <AdminTenantNew />
          </TenantProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/tenants/:tenantId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <TenantProvider>
            <AdminTenantDetail />
          </TenantProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/tenants/:tenantId/admins",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <TenantAdminsProvider>
            <AdminTenantsAssignedAdmins />
          </TenantAdminsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/users",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <UsersProvider>
            <AdminUsers />
          </UsersProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/users/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <UserProvider>
            <AdminUserNew />
          </UserProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/users/:userId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <UserProvider>
            <AdminUserDetail />
          </UserProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },

  {
    path: "/admin/user-management",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <UsersProvider>
            <AdminUserManagement />
          </UsersProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/buyers/upload",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <UserProvider>
            <AdminBuyersDataUpload />
          </UserProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },

  {
    path: "/admin/vendors",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <VendorsProvider>
            <AdminVendors />
          </VendorsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/vendors/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <VendorProvider>
            <AdminVendorNew />
          </VendorProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/vendors/:vendorId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <VendorProvider>
            <AdminVendorDetail />
          </VendorProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/views",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ViewsProvider>
            <AdminViews />
          </ViewsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/views/new",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ViewProvider>
            <AdminViewsNew />
          </ViewProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/views/:viewId",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ViewProvider>
            <AdminViewDetail />
          </ViewProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
  {
    path: "/admin/views/:viewId/views",
    element:
      isAuthenticated && (isRoot || isAdmin) ? (
        <CombinedAdminProvider>
          <ViewsProvider>
            <AdminViews />
          </ViewsProvider>
        </CombinedAdminProvider>
      ) : (
        <Navigate to="/" />
      ),
  },
];

/**
 * Fallback will display a loading animation after the given delay in msec.
 *
 * @returns delay in milliseconds
 */
const Fallback = ({ delay }: { delay: number }): JSX.Element => {
  const [isReady] = useTimeout(delay);

  return isReady() ? (
    <div className="flex h-screen animate-pulse items-center justify-center space-x-2 delay-1000">
      <div className="h-8 w-8 rounded-full bg-surface-disabled"></div>
      <div className="h-8 w-8 rounded-full bg-surface-disabled"></div>
      <div className="h-8 w-8 rounded-full bg-surface-disabled"></div>
    </div>
  ) : (
    <></>
  );
};

/**
 * Main router of the app.
 */
const App: React.FC = () => {
  const { isAuthenticated, profile } = useAuth();
  const routing = useRoutes(
    routes(isAuthenticated, profile?.isRoot, profile?.isAdmin),
  );
  const { i18n } = useTranslation();

  React.useEffect(() => {
    const { language } = i18n;

    if (profile && profile.language !== language) {
      updateUserLanguage(language as Locale);
    }
  }, [profile]);

  return (
    <React.Suspense fallback={<Fallback delay={2000} />}>
      {routing}
    </React.Suspense>
  );
};

export default App;
